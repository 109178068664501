import React, { useState } from "react";
import { MDBBtn, MDBIcon, MDBInput } from "mdb-react-ui-kit";
import logo from "../resources/Venture-Connect-Horizontal-Logo-Full-Color.png";
import envConfig from "../envConfig";

const ReportRequestPage: React.FC = () => {
  const [getFormData, setFormData] = useState({
    mcNumber: "",
    order1: "",
    order2: "",
    order3: "",
    order4: "",
    order5: "",
  });

  const setValues = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setFormData({
      ...getFormData,
      [e.target.name]: e.target.value,
    });
  };

  const validateForm = () => {
    let retVal = true;
    if (getFormData.mcNumber === "") {
      retVal = false;
    }
    if (getFormData.order1 === "") {
      retVal = false;
    }
    return retVal;
  };

  const handleNulls = (label: String, text: String) => {
    if (text === "") {
      return "";
    } else {
      return label + "=" + text;
    }
  };

  const getReportURL = () => {
    return (
      window.location.href +
      envConfig.REPORT_SERVER_DOMAIN +
      handleNulls("?MCNum", checkMCNumber(getFormData.mcNumber)) +
      handleNulls("&Order1", getFormData.order1) +
      handleNulls("&Order2", getFormData.order2) +
      handleNulls("&Order3", getFormData.order3) +
      handleNulls("&Order4", getFormData.order4) +
      handleNulls("&Order5", getFormData.order5)
    );
  };

  const checkMCNumber = (mcNumber: String) => {
    if (mcNumber.toUpperCase().startsWith("MC")) {
      return mcNumber.toUpperCase();
    } else {
      return "MC" + mcNumber;
    }
  };

  const openInNewTab = (url: string | undefined) => {
    if (validateForm()) {
      window.open(url, "_blank", "noreferrer");
    }
  };

  const getColor = () => {
    if (validateForm()) {
      return "success";
    } else {
      return "danger";
    }
  };

  return (
    <div className="row g-3 justify-content-center col-md-4 offset-md-4 ">
      <div></div>
      <img src={logo} alt="Venture Connect" />
      <div className="h4 text-center mb-4 mt-4 col-md-11">
        Pay Status Research
      </div>
      <div className="col-md-11">
        <MDBInput
          label="MC Number"
          id="mcNumber"
          name="mcNumber"
          type="text"
          required
          onChange={(e) => {
            setValues(e);
          }}
          value={getFormData.mcNumber}
        />
      </div>

      <div className="col-md-11">
        <MDBInput
          label="Order 1"
          id="order1"
          name="order1"
          type="text"
          required
          onChange={(e) => {
            setValues(e);
          }}
          value={getFormData.order1}
        />
      </div>
      <div className="col-md-11">
        <MDBInput
          label="Order 2"
          id="order2"
          name="order2"
          type="text"
          required
          onChange={(e) => {
            setValues(e);
          }}
          value={getFormData.order2}
        />
      </div>
      <div className="col-md-11">
        <MDBInput
          label="Order 3"
          id="order3"
          name="order3"
          type="text"
          required
          onChange={(e) => {
            setValues(e);
          }}
          value={getFormData.order3}
        />
      </div>
      <div className="col-md-11">
        <MDBInput
          label="Order 4"
          id="order4"
          name="order4"
          type="text"
          required
          onChange={(e) => {
            setValues(e);
          }}
          value={getFormData.order4}
        />
      </div>
      <div className="col-md-11">
        <MDBInput
          label="Order 5"
          id="order5"
          name="order5"
          type="text"
          required
          onChange={(e) => {
            setValues(e);
          }}
          value={getFormData.order5}
        />
      </div>

      <div className="text-center mt-4">
        <MDBBtn
          color={getColor()}
          outline
          onClick={() => openInNewTab(getReportURL())}
        >
          Submit
          <MDBIcon far icon="paper-plane" className="ml-2" />
        </MDBBtn>
      </div>
    </div>
  );
};

export default ReportRequestPage;
